import AdSense from 'react-adsense';

export default function Ads(adsType) {
    return (
        <>
            <AdSense.Google
                client='ca-pub-8965678723704510'
                slot={adsType.adsSlotID}
                style={{display: 'block' }}
                layout={adsType.adsLayout}
                format= {adsType.adsFormat} 
                responsive= 'true'
            />
        </>
    );
};

